import { render, staticRenderFns } from "./MobileMenu.vue?vue&type=template&id=049f7146&scoped=true"
import script from "./MobileMenu.vue?vue&type=script&lang=ts"
export * from "./MobileMenu.vue?vue&type=script&lang=ts"
import style0 from "./MobileMenu.scss?vue&type=style&index=0&id=049f7146&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049f7146",
  null
  
)

export default component.exports