export class Bank {
  constructor(data) {
    this.id = data.id;
    this.idMarketplace = data.id_for_marketplace;
    this.name = data.name;
    this.logo = data.logo;
    this.parametersComments = data.parametersComments;
    this.baseRate = data.baseRate;
    this.minRate = data.minRate;
    this.maxRate = data.maxRate;
    this.comboRate = data.comboRate;
    this.effectiveRateMin = data.effectiveRateMin;
    this.effectiveRateMax = data.effectiveRateMax;
    this.minDiscountRate = null;
    this.paymentRateReduction = null;
    this.incomeRateReduction = null;
    this.overpaymentRateReduction = null;
    this.commission = data.commissionAndPreferences.commission;
    this.childOutcomeMax = data.childOutcomeMax;
    this.childOutcomeMin = data.childOutcomeMin;
    this.creditCardLimitPercentMax = data.creditCardLimitPercentMax;
    this.creditCardLimitPercentMin = data.creditCardLimitPercentMin;
    this.notTakeAccountCreditCardLimitUpTo =
      data.notTakeAccountCreditCardLimitUpTo;
    this.paymentPercent = data.paymentPercent;
    this.polisOnlineCode = data.polisOnlineCode;
    this.managers = data.managers;
    this.website = data.website;
    this.commissionWithSalaryProject = data.commissionWithSalaryProject;
    this.countDiscount = 0;
    this.promo = {};
    this.promoActive = 0;
    this.allPromo = {};
    this.bankChecked = false;
    this.bankHidden = false;
    this.paidRateReduction = data.paidRateReduction;
    this.insuranceLife = {
      value: data.insuranceLife,
      maxValue: data.insuranceLifeMax,
      minValue: data.insuranceLifeMin,
      applied: false,
    };
    this.insuranceTitle = {
      value: data.insuranceTitle,
      applied: false,
    };
    this.salaryProject = {
      ...data.salaryProject,
      applied: false,
    };
    this.preferences = {
      ...data.commissionAndPreferences.preferences,
      applied: false,
    };
    this.fixedRate = {
      id: null,
      rate: 0,
    };
    this.myCommission = data.myCommission;
    this.myPreferences = {
      ...data.myPreferences,
      applied: false,
    };
    this.tymy = data.tymy;
    this.cabinetLink = data.pa_link;
    this.duringConstructionPromo = {
      rate: undefined,
      overpayment: undefined,
      payment: undefined,
    };
    this.onlyToRate = data.onlyToRate;
    this.additionalInfo = data.additionalInfo;
  }

  // setInsurance() {
  //   return [13, 7].includes(this.id);
  // }
}

export const optionTypes = {
  plus: ["insuranceTitle", "insuranceLife"],
  minus: ["preferences", "myPreferences"],
};
