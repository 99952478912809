export const getDefaultCalcState = () => {
    return {
        token: "",
        loader: false,
        loaderWaiters: 0,
        modules: {
            calculator: false,
            additionalParameters: false,
            banksList: false,
            insurance: false,
            news: false,
            directory: false,
            order: false,
            analytics: false,
            promos: false,
            check: false,
            loadingModules: false,
        },
        parameters: {},
        financialBurden: {},
        banks: {},
        banksLoading: false,
        promosLoading: false,
        bankPreviewLoading: false,
        bankPreviewIds: [],
        bankPreviewValues: {},
        requestPromotionCounter: 0,
        stopRequest: false,
        result: {
            count: 0,
            rates: [0, 0],
            payments: [0, 0],
            incomes: [0, 0],
            light: null,
            isLight: true,
            banks: [],
            rules: {
                Block: [],
                Show: [],
            },
            max: null,
            comboRate: null,
        },
        resultWithIncome: {
            count: 0,
            rates: [0, 0],
            payments: [0, 0],
        },
        filteredBanks: {},
        isIncomeAccountable: false,
        additionalParametersCategories: {},
        additionalParametersCategoriesForm: [],
        additionalParameters: {},
        checkedAdditionalParameters: {},
        choosedBanks: {
            ids: {},
            idsMarketplaces: {},
            count: 0,
        },
        hiddenBanks: [],
        confirmModal: {
            show: false,
            text: "",
            bankId: null,
        },
        alertModal: {
            show: false,
            text: "",
        },
        isBlockCalculator: false,
        checkedParameters: [],
        typesLoan: [],
        categories: [],
        showRewardUser: false,
        showPreferencesUser: false,
        insuranceParameters: {
        //   buildAge: 0 /*дата постройки. Значения: -1 - новостройка; 0 - вторичка*/,
        //   hasGas: false /*наличие газосодержащих сооружений bool*/,
        //   bankCode: "" /*код банка, который был передан в list как параметр polisOnlineCode*/,
        //   creditSum: 0 /*остаток кредита*/,
        //   clientAge: 0 /* возраст заемщика*/,
        //   gender: "" /*пол заемщика (M, F)*/,
        //   creditNumber: false /*номер кредитного договора (0 - если не указан)*/,
        //   fireDanger: false /*есть ли деревянные перекрытия bool*/,
        //   ownership: true ,bool, есть ли право на собственность. Его нет только в случаях, когда недвижимость - новостройка
        //   creditStartDate: data.isVTB, //если ВТБ, то дата нужна
        //   regionId: data.isVTB ? state.parameters[0].value : -1, // id региона если банк - ВТБ, иначе -1
        },
        insuranceResult: [],
        templates: {
            isAdmin: false,
            isCompany: false,
            publicTemplates: [],
            selfTemplates: [],
            companyTemplates: [],
            loading: true,
            selectedTemplate: undefined,
            limit: null,
        },
        infoAssessment: {
            decorationType: {},
        },
        isClientVersion: true,
        clientVersion: 0,
        isReduceRate: false,
        banksLogos: {},
        bankAdvertises: {},
        constructionCompanyAdvertises: {},
        secondFormHasFilledValues: false,
        isFirstBlockValid: false,
        isValid: false,
        topBank: undefined,
        topUpdateDate: null,
    };
};
export const calcState = getDefaultCalcState();
